import Stack from "react-bootstrap/Stack"
import {ListGroup, Button, Row, Col} from "react-bootstrap";


export default function({ openPositions, exitPosition, style, expiration}){
    // const [style, setStyle] = useState({ backgroundColor: 'white' });
    // const style = { backgroundColor: 'white' };

  const getStyle = (position) => {
    // console.log("position", position);  
      const {pnlPercentage}  = position;
      const s1 = {};
      s1.backgroundColor = "white";
      if (pnlPercentage < 0 && pnlPercentage > -30) {
        s1.backgroundColor = "#ff000042";

      } 
      else if (pnlPercentage < -30) {
        s1.backgroundColor = "#ff0000a1";
      } else if(pnlPercentage > 0 && pnlPercentage < 30) {
        s1.backgroundColor = "#0bac6140";
      } else if(pnlPercentage > 30 ) {
        s1.backgroundColor = "#0bac61bd";
      }
      // setStyle(s1);
      return s1;
                    
  };
  return <>
      {openPositions
        .map((position) => (
          <Row style={getStyle(position)}>
            <Col>
            <Row style={{padding:"5px", fontWeight:"bold"}}>
              <Col md={4}>{position.contract?.symbol} ${position.contract?.strike} {position.contract?.right === "C" ? "Call" : "Put"}   </Col>
              <Col md={4}> {position.pos} </Col>
              <Col md={4}>{position.pnlPercentage?.toFixed(2)}% {position.trailingPercentage?.toFixed(2)}%</Col>
            </Row>
            <Row style={{padding:"5px", fontWeight:"bold"}}>
              <Col md={4}>${position.value?.toFixed(2)} </Col>
              <Col md={4}>{expiration?.replace("2023-", "").replace("-", "/")}</Col>
              <Col md={4}>${position.pnl?.toFixed(2)}
                      <Button variant="danger" style={{padding: "2px", float: "right"}} className="exit-button" onClick={() => exitPosition(position.id)}>
                      Exit
                    </Button>
                  </Col>
            </Row>
            <Row>
              <Col>
                    
                  </Col>
            </Row>
            </Col>
          </Row>
        ))
      }
  </>
}

