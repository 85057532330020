import {Modal, Button, Container, Row, Col, Form, InputGroup} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import loadFirebase from "./firebase.js";
const currency = "USD";
export default function({show, setShowNewConfigModal, config, editing, configToEdit}) {
    const firebase = loadFirebase(config);

    // Futures/Futures Options/Equity Options/Equity
    const [name, setName] = useState();
    const [wipeOptionsflow, setWipeOptionsflow] = useState(false);
    const [tradeType, setTradeType] = useState(null);
    const [signalType, setSignalType] = useState(null);
    const [synthetic, setSynthetic] = useState();
    const [symbol, setSymbol] = useState();
    const [lotSize, setLotSize] = useState();
    const [currency, setCurrency] = useState("USD");
    const [exchange, setExchange] = useState();
    const [tradeResubmitFrequency, setTradeResubmitFrequency] = useState();
    const [onlySignals, setOnlySignals] = useState(false);
    const [sendText, setSendText] = useState(false);
    const [sendCall, setSendCall] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 4)));
    const [syntheticTradingStartingBalance, setSyntheticTradingStartingBalance] = useState();
    const [multiplier, setMultiplier] = useState();
    const [localSymbol, setLocalSymbol] = useState();
    const [tradingClass, setTradingClass] = useState();
    const [quantity, setQuantity] = useState();
    const [takeProfitPercentage, setTakeProfitPercentage] = useState();
    const [stopLossPercentage, setStopLossPercentage] = useState();
    const [reEnterAfterTakeProfit, setReEnterAfterTakeProfit] = useState();
    const [reEnterAfterStopLoss, setReEnterAfterStopLoss] = useState();
    const [fastestFrequency, setFastestFrequency] = useState();
    const [interval1, setInterval1] = useState();
    const [interval2, setInterval2] = useState();
    const [interval1Unit, setInterval1Unit] = useState();
    const [interval2Unit, setInterval2Unit] = useState();
    const [useVol5MinThreshold, setUseVol5MinThreshold] = useState()
    const [vol5MinValue, setVol5MinValue] = useState()
    const [interval3, setInterval3] = useState();
    const [interval4, setInterval4] = useState();
    const [interval3Unit, setInterval3Unit] = useState();
    const [interval4Unit, setInterval4Unit] = useState();
    const [trailingPercentage, setTrailingPercentage] = useState();
    const [strikeStretch, setStrikeStretch] = useState();


    //Futures Options/Futures
    const [futExpiry, setFutExpiry] = useState();

    //Futures Options
    const [fopExpiry, setFopExpiry] = useState();
    

    //Equity Options
    const [OpExpiry, setOpExpiry] = useState();

    const [symbols, setSymbols] = useState([]);
    const [autoCompleteData, setAutoCompleteData] = useState({});
    const [contracts, setContracts] = useState([]);
    const [exchanges, setExchanges] = useState([]);
    const [expiry, setExpiry] = useState([]);   
    const [localSymbols, setLocalSymbols] = useState([]);
    const [tradingClasses, setTradingClasses] = useState([]);
    const [strikeStep, setStrikeStep] = useState(0.25);
    const [strikesMax , setStrikesMax] = useState(0);
    const [strikesMin , setStrikesMin] = useState(0);

    
    const [configs, setConfigs] = useState([]);
    const [fopExpiryData, setFopExpiryData] = useState({}); 

    // const [tradingClasses, setTradingClasses] = useState([]);

    useEffect(() => {

        if(autoCompleteData&& autoCompleteData[tradeType] && autoCompleteData[tradeType][symbol]){
            if(tradeType === "futuresOptions"){
                setExchanges(autoCompleteData[tradeType][symbol].exchanges);
                const ex = autoCompleteData[tradeType][symbol].expiries;
                setExpiry(Object.keys(ex));
                setFopExpiryData(ex);
                setLocalSymbols(autoCompleteData[tradeType][symbol].localSymbols);
                setTradingClasses(autoCompleteData[tradeType][symbol].tradingClasses);
                setContracts(autoCompleteData[tradeType][symbol].conIds);
            }
            if(tradeType === "equityOptions"){
                setExchanges(autoCompleteData[tradeType][symbol].exchanges);
                setExpiry(Object.keys(autoCompleteData[tradeType][symbol].expiries));
                setLocalSymbols(autoCompleteData[tradeType][symbol].localSymbols);
                setTradingClasses(autoCompleteData[tradeType][symbol].tradingClasses);
                setContracts(autoCompleteData[tradeType][symbol].conIds);
            }
            
        }
        
    }, [symbol, tradeType]);

    

    useEffect(() => {
        if(autoCompleteData && autoCompleteData[tradeType] && autoCompleteData[tradeType][symbol] && tradeType === "equityOptions"){
            const expiry = autoCompleteData[tradeType][symbol].expiries[OpExpiry];
            setStrikesMax(expiry?.max);
            setStrikesMin(expiry?.min);
            setStrikeStep(expiry?.step);
            setLocalSymbols(expiry?.localSymbols);
        }

        if(autoCompleteData && autoCompleteData[tradeType] && autoCompleteData[tradeType][symbol] && tradeType === "futuresOptions"){
            const expiry = autoCompleteData[tradeType][symbol].expiries[futExpiry];
            setStrikesMax(expiry?.max);
            setStrikesMin(expiry?.min);
            setStrikeStep(expiry?.step);
            // setLocalSymbols(expiry.localSymbols);
        }

    }, [OpExpiry, futExpiry])

   
    useEffect(() => { 
        firebase.autoCompleteRef.doc(currency).get().then((doc) => {
            if(doc.exists){
                let data = doc.data();
                for(const tt of Object.keys(data)){
                    // data[tt] = 
                    const xx = data[tt];
                    for(const ss of Object.keys(xx)){
                        xx[ss] = JSON.parse(xx[ss]);
                    }
                    data[tt] = xx;
                }
                if(data && data[tradeType]){
                    setSymbols(Object.keys(data[tradeType]));
                    if(Object.keys(data[tradeType]).length > 0){
                        setSymbol(Object.keys(data[tradeType])[0]);
                    }
                }
                
                setAutoCompleteData(data);

            }
        });
    }, [tradeType]);      

    useEffect(() => {
        // if(!editing) return;
        // console.log("configToEdit", configToEdit?.config.quantity);
        setName(configToEdit?.name);
        setWipeOptionsflow(configToEdit?.wipeOptionsflow);
        setSynthetic(configToEdit?.synthetic);
        setSymbol(configToEdit?.symbol);
        setLotSize(configToEdit?.lotSize);
        setCurrency(configToEdit?.currency || "USD");
        setExchange(configToEdit?.exchange);
        setTradeResubmitFrequency(configToEdit?.tradeResubmitFrequency);
        setFutExpiry(configToEdit?.config.expiry.fut);
        setFopExpiry(configToEdit?.config.expiry.fop);

        //Equity Options
        setOpExpiry(configToEdit?.config.expiry.op);
        //
        setTradingClass(configToEdit?.config.tradingClass);
        setQuantity(configToEdit?.config.quantity);
        setStrikeStretch(configToEdit?.config.strikeStretch);
        setTrailingPercentage(configToEdit?.config.trailingPercentage);
        setTakeProfitPercentage(configToEdit?.config.takeProfitPercentage);
        setStopLossPercentage(configToEdit?.config.stopLossPercentage);
        setReEnterAfterTakeProfit(configToEdit?.config.reEnterAfterTakeProfit);
        setReEnterAfterStopLoss(configToEdit?.config.reEnterAfterStopLoss);
        setFastestFrequency(configToEdit?.config.fastestFrequency);
        setInterval1(configToEdit?.config.interval1);
        setInterval2(configToEdit?.config.interval2);
        setInterval1Unit(configToEdit?.config.interval1Unit);
        setInterval2Unit(configToEdit?.config.interval2Unit);
        setInterval3(configToEdit?.config.interval3);
        setInterval4(configToEdit?.config.interval4);
        setInterval3Unit(configToEdit?.config.interval3Unit);
        setInterval4Unit(configToEdit?.config.interval4Unit);
        setStartDate(configToEdit?.config.startDate);
        setEndDate(configToEdit?.config.endDate);
        setUseVol5MinThreshold(configToEdit?.config.useVol5MinThreshold);
        setVol5MinValue(configToEdit?.config.vol5MinValue);
        setMultiplier(configToEdit?.multiplier || 20);
        setLocalSymbol(configToEdit?.config.localSymbol || 'NQU3');
        setSyntheticTradingStartingBalance(configToEdit?.syntheticTradingStartingBalance || 10000);
        setOnlySignals(configToEdit?.onlySignals);
        setSendText(configToEdit?.sendText);
        setSendCall(configToEdit?.sendCall);
        setTradeType(configToEdit?.tradeType || "featuresOptions");
        setSignalType(configToEdit?.signalType || "flow");

        if(!configToEdit) {
            setTradeType(null);
            setStartDate(new Date());
            setEndDate(new Date(new Date().setDate(new Date().getDate() + 4)));
            setOnlySignals(false);
            setSendText(false); 
            setSendCall(false);
            setTradeType("futuresOptions");
        }

    }, [configToEdit, editing]);

    useEffect(() => {
        firebase.configsRef.onSnapshot((snapshots) => {
            const tmpConfigs = [];
            snapshots.forEach((configSnapshot) => {
              const config = configSnapshot.data();
              tmpConfigs.push(config);
            });
            setConfigs([...tmpConfigs]);
          });
    }, []);
    const onWheelBlur = (e) => e.target.blur();
    const dateToInputValue = (date) => {
        if(date && date.getTime)
            return new Date(date.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19)
        // return date.toISOString().slice(0, 16);
        const tmpDate = date.toDate();
        return new Date(tmpDate.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19)
      } 


    const handelSubmit = async () => {

        const expiry  = {
            fut: futExpiry || dateToInputValue(new Date()).split("T")[0],
            fop: fopExpiry || dateToInputValue(new Date()).split("T")[0],
            op: OpExpiry || dateToInputValue(new Date()).split("T")[0]
        };
        const configsKeys = configs.filter((c)=> c.name !== name).map((config) => `${config.symbol}-${config?.config?.strikeStretch}-${config?.config?.expiry?.fut || config?.config?.expiry?.fop || config?.config?.expiry?.op}`);
        if(configsKeys.includes(`${symbol}-${strikeStretch}-${expiry.fut || expiry.fop || expiry.op}`)){
         alert("Config already exists");    
         return;
        }
        const dataToSave = {
            name,
            synthetic: synthetic?true: false,
            wipeOptionsflow: !!wipeOptionsflow,
            status: "stopped",
            exchange,
            syntheticTradingStartingBalance: syntheticTradingStartingBalance || 10000,
            symbol,
            currency : "USD",
            lotSize: parseInt(lotSize || 1),
            tradeResubmitFrequency: parseInt(tradeResubmitFrequency || 1),
            onlySignals: !!onlySignals,
            sendText: !!sendText,
            sendCall: !!sendCall,
            tradeType: tradeType || "futuresOptions",
            signalType: signalType || "flow",
            config: {
                expiry: expiry,
                tradingClass: tradingClass || "",
                quantity: parseInt(quantity || 1),
                
                strikeStretch: parseInt(strikeStretch || 0),
                trailingPercentage: parseFloat(trailingPercentage || 0),
                takeProfitPercentage: parseFloat(takeProfitPercentage || 0),
                stopLossPercentage: parseFloat(stopLossPercentage || 0),
                reEnterAfterTakeProfit: !!reEnterAfterTakeProfit,
                reEnterAfterStopLoss: !!reEnterAfterStopLoss,
                fastestFrequency: fastestFrequency || '10S',
        
                interval1: interval1 || '2',
                interval2: interval2 || '5',
                interval1Unit: interval1Unit || 'mins',
                interval2Unit: interval2Unit || 'mins',
        
                interval3: interval3 || '2',
                interval4: interval4 || '5',
                interval3Unit: interval3Unit || 'mins',
                interval4Unit: interval4Unit || 'mins',
                startDate: startDate || new Date(),
                useVol5MinThreshold: !!useVol5MinThreshold,
                vol5MinValue: parseFloat(vol5MinValue || 0),
                multiplier: parseInt(multiplier || 20),
                localSymbol: localSymbol || 'NQU3',
                endDate: endDate || new Date(new Date().setHours(new Date().getHours() + 4))
                
              }

        };
        await firebase.configsRef.doc(name).set(dataToSave, {merge: true});
        setShowNewConfigModal(false);
    };
    return (<Modal show={show} onHide={()=>setShowNewConfigModal(false)}>
        <Modal.Header closeButton>
            {!editing && <Modal.Title>New Trade (`{tradeType}`)</Modal.Title>}
            {editing && <Modal.Title>Edit Trade `{tradeType}` - `{name}`</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Container>
                    <Row>
                        <Col>
                            <Form.Group style={{display: 'flex', flexDirection: 'row-reverse', justifyContent: 'start', gap: 7 }}>
                                <Form.Label>Wipe optionsflow data on start?</Form.Label>
                                <Form.Check defaultChecked={wipeOptionsflow} onChange={(e)=>{setWipeOptionsflow(e.target.checked)}}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Select label="Select Signal Type" value={signalType} onChange={(e)=>{setSignalType(e.target.value)}} >
                                    <option value="flow">Flow only</option>
                                    <option value="flowAndSignal">Flow with MA Cross</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Select {...(editing? {disabled: true}: {})} label="Select Trade Type" value={tradeType} onChange={(e)=>{setTradeType(e.target.value)}} >
                                    <option value="futuresOptions">Futures Options</option>
                                    <option value="equity">Equity</option>
                                    <option value="equityOptions">Equity Options</option>
                                    <option value="futures">Futures</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Check label="Synthetic" defaultChecked={synthetic} onChange={(e)=>{setSynthetic(e.target.checked)}}/>
                            </Form.Group>
                            {synthetic === true && <Form.Group>
                                    <Form.Label>Balanace</Form.Label>
                                    <Form.Control
                                        placeholder='Enter Balance' 
                                        value={syntheticTradingStartingBalance}
                                        onChange={(e)=>{setSyntheticTradingStartingBalance(e.target.value)}}/>
                                </Form.Group>
                            }
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Name" value={name} onChange={(e)=>setName(e.target.value)} {...(editing? {disabled: true}: {})}/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Symbol</Form.Label>
                                <Form.Select placeholder="Enter Symbol" value={symbol} onChange={(e)=>setSymbol(e.currentTarget.value)}>
                                    {symbols && symbols.map((symbol) => <option key={symbol} value={symbol}>{symbol}</option>)}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>lotSize</Form.Label>
                                <Form.Control type="text" placeholder="Enter lotSize" value={lotSize} onChange={(e)=>setLotSize(e.target.value)}/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Local Symbol</Form.Label>
                                <Form.Select placeholder="Enter Local Symbol" value={localSymbol} onChange={
                                    (e)=>{
                                        // const val = e.currentTarget.value;

                                        setLocalSymbol(e.target.value);
                                        let ls = localSymbols.filter((ls)=> ls.includes(e.target.value));
                                        if(ls.length > 0){
                                            setFutExpiry(ls[0]?.split("-")[1]);
                                        }
                                        
                                    
                                    }}>
                                    {localSymbols && localSymbols.map((ls) => <option selected={ls.split("-")[0] === localSymbol} key={ls.split("-")[0]} value={ls.split("-")[0]}>{ls}</option>)}
                                </Form.Select>
                                {/* <Form.Control type="text" placeholder="Enter Local Symbol" defaultValue={localSymbol} onChange={(e)=>setLocalSymbol(e.target.value)}/> */}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>currency</Form.Label>
                                <Form.Control disabled type="text" placeholder="Enter currency" value={currency} onChange={(e)=>setCurrency(e.target.value)}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>exchange</Form.Label>
                                <Form.Select placeholder="Enter exchange" value={exchange} onChange={(e)=>setExchange(e.currentTarget.value)}>
                                    {  exchanges && exchanges.map((exchange) => <option key={exchange} value={exchange}>{exchange}</option>)}
                                </Form.Select>
                                {/* <Form.Control type="text" placeholder="Enter exchange" defaultValue={exchange} onChange={(e)=>{setExchange(e.target.value)}}/> */}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>trade Resubmit Frequency</Form.Label>
                                <Form.Control type="text" placeholder="Enter trade Resubmit Frequency" value={tradeResubmitFrequency} onChange={(e)=>setTradeResubmitFrequency(e.target.value)}/>
                            </Form.Group>


                            <Form.Group>
                                <Form.Label>Multiplier</Form.Label>
                                <Form.Control type="text" placeholder="Enter Multiplier" value={multiplier} onChange={(e)=>setMultiplier(e.target.value)}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            {(tradeType === "futuresOptions" || tradeType==="futures") && <>
                                {/* <Form.Group>
                                    <Form.Label>FUT Expiry</Form.Label>
                                    <Form.Select placeholder="Enter FUT Expiry" value={futExpiry} onChange={(e)=>setFutExpiry(e.currentTarget.value)}>
                                        {expiry.map((expiry) => <option key={expiry} value={expiry}>{expiry}</option>)}
                                    </Form.Select>
                                </Form.Group> */}

                                {tradeType === "futuresOptions" && <Form.Group>
                                    <Form.Label>FOP Expiry</Form.Label>
                                    <Form.Control type="date" value={fopExpiry} onChange={(e) => setFopExpiry(e.target.value)} />
                                </Form.Group>}
                            </>}

                            {tradeType === "equityOptions" && <Form.Group>
                                    <Form.Label>Options Expiry</Form.Label>
                                    {/* <Form.Control type="date" defaultValue={OpExpiry} onChange={(e) => setOpExpiry(e.target.value)} /> */}
                                    <Form.Select placeholder="Enter Options Expiry" value={OpExpiry} onChange={(e)=>setOpExpiry(e.currentTarget.value)}>
                                        {expiry && expiry.map && expiry.map((expiry) => <option key={expiry} value={expiry}>{expiry}</option>)}
                                    </Form.Select>
                            </Form.Group>}


                            <Form.Group>
                                <Form.Label>Trading Class</Form.Label>
                                <Form.Select placeholder="Enter Trading Class" value={tradingClass} onChange={(e)=>setTradingClass(e.currentTarget.value)}>
                                    {tradingClasses && tradingClasses.map((tradingClass) => <option key={tradingClass} value={tradingClass}>{tradingClass}</option>)}   
                                </Form.Select>
                                {/* <Form.Control type="text" defaultValue={tradingClass} onChange={(e) => setTradingClass(e.target.value)} /> */}
                            </Form.Group>

                            

                            <Form.Group>
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control type="datetime-local"  value={dateToInputValue(startDate)} onChange={(e) => setStartDate(new Date(Date.parse(e.target.value)))} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>End Date</Form.Label>
                                <Form.Control type="datetime-local" value={dateToInputValue(endDate)} onChange={(e) => setEndDate(new Date(Date.parse(e.target.value)))} />
                            </Form.Group>


                            <Form.Group>
                                <div style={{display: 'flex', flexDirection: 'row-reverse', justifyContent: 'start', gap: 7 }}>
                                    <Form.Label>Use Vol5Min Threshold?</Form.Label>
                                    <Form.Check checked={useVol5MinThreshold} onChange={(e) => setUseVol5MinThreshold(e.target.checked)} />
                                </div>
                                <Form.Label>Vol5Min Threshold</Form.Label>
                                <Form.Control placeholder='Vol5Min Threshold' type= "number" disabled={!useVol5MinThreshold} value={vol5MinValue} onChange={(e) => setVol5MinValue(e.target.value)} step="0.00001"/>
                            </Form.Group>
                            <Form.Group>
                                
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Quantity</Form.Label>
                                <Form.Control type="number" placeholder="Example 1 or 2" value={quantity} onWheel={onWheelBlur} onChange={(e) => setQuantity(e.target.value)} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Strike Stretch Value</Form.Label>
                                {tradeType === "futuresOptions" &&
                                     <Form.Control type="number" step={strikeStep} placeholder={`from ${strikesMin} to ${strikesMax} changes by ${strikeStep}`} min={strikesMin} max={strikesMax} value={strikeStretch} onWheel={onWheelBlur} onChange={(e) => setStrikeStretch(e.target.value)} />
                                }
                                {
                                    tradeType === "equityOptions" &&
                                    <Form.Control type="number" step={strikeStep} placeholder={`from ${strikesMin} to ${strikesMax} changes by ${strikeStep}`} min={strikesMin} max={strikesMax} value={strikeStretch} onWheel={onWheelBlur} onChange={(e) => setStrikeStretch(e.target.value)} />

                                }
                                
                                
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Trailing percentage</Form.Label>
                                <InputGroup>
                                <Form.Control type="number" placeholder="Example 7.5%" step={0.25} value={trailingPercentage} onWheel={onWheelBlur} onChange={(e) => setTrailingPercentage(e.target.value)} />
                                <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Take profit percentage</Form.Label>
                                <InputGroup>
                                <Form.Control type="number" placeholder="Example 3.5%" step={0.25} value={takeProfitPercentage} onWheel={onWheelBlur} onChange={(e) => setTakeProfitPercentage(e.target.value)} />
                                <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Stop loss percentage</Form.Label>
                                <InputGroup>
                                <Form.Control type="number" placeholder="Example 2.5%" step={0.25} value={stopLossPercentage} onWheel={onWheelBlur} onChange={(e) => setStopLossPercentage(e.target.value)} />
                                <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group>
                                <Form.Check type="checkbox" label="Re-enter after take profit" defaultChecked={reEnterAfterTakeProfit} onChange={(e) => setReEnterAfterTakeProfit(e.target.checked)} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Check type="checkbox" label="Re-enter after stop loss" defaultChecked={reEnterAfterStopLoss} onChange={(e) => setReEnterAfterStopLoss(e.target.checked)} />
                            </Form.Group>


                            <Form.Group>

                            <Form.Check  type="checkbox" 
                                label="JUST SHOW SIGNALS"
                                checked={onlySignals}
                                onChange={async (e) => { setOnlySignals(e.target.checked);}}
                                
                            />
                            </Form.Group>

                            <Form.Group>
                                <Form.Check 
                                    type="checkbox" 
                                    label="SEND TEXT when signals change"
                                    checked={sendText}
                                     onChange={(e) => setSendText(e.target.checked)} />
                            </Form.Group>


                            <Form.Group>
                                <Form.Check 
                                    type="checkbox" 
                                    label="CALL when signals change"
                                    checked={sendCall}
                                    onChange={async (e) => { setSendCall(e.target.checked);}} 
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Fastest frequency</Form.Label>
                                <Form.Control type="text" placeholder="Example 10S or 1 or 2H" value={fastestFrequency} onChange={(e) => setFastestFrequency(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Interval 1</Form.Label>
                                <Form.Control type="text" placeholder="Example 1, 2 " value={interval1} onChange={(e) => setInterval1(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Interval 2</Form.Label>
                                <Form.Control type="text" placeholder="Example 1, 2 " value={interval2} onChange={(e) => setInterval2(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Interval 3</Form.Label>
                                <Form.Control type="text" placeholder="Example 1, 2 " value={interval3} onChange={(e) => setInterval3(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Interval 4</Form.Label>
                                <Form.Control type="text" placeholder="Example 1, 2 " value={interval4} onChange={(e) => setInterval4(e.target.value)} />
                            </Form.Group>


                            <Form.Group className="mb-3">
                                <Form.Label>Interval 1 Unit</Form.Label>
                                <Form.Control type="text" placeholder="mins" value={interval1Unit} onChange={(e) => setInterval1Unit(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Interval 2 Unit</Form.Label>
                                <Form.Control type="text" placeholder="mins" value={interval2Unit} onChange={(e) => setInterval2Unit(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Interval 3 Unit</Form.Label>
                                <Form.Control type="text" placeholder="mins" value={interval3Unit} onChange={(e) => setInterval3Unit(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Interval 4 Unit</Form.Label>
                                <Form.Control type="text" placeholder="mins" value={interval4Unit} onChange={(e) => setInterval4Unit(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={()=>setShowNewConfigModal(false)}>
                Close
            </Button>
            <Button variant="primary" onClick={handelSubmit}>
                Save Changes
            </Button>
        </Modal.Footer>
    </Modal>)
}