import { useState, useEffect, useMemo, useContext} from "react";
import ListGroup from "react-bootstrap/ListGroup";
import {Form as BFORM, Col, Row, Button, Badge , InputGroup, Container, } from "react-bootstrap";
import Stack from "react-bootstrap/Stack"
import loadFirebase from "./firebase.js";
import { Form, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";
import NewConfigModal from "./NewCofigModal";

import "./App.css";
import ConfigModal from "./ConfigModal";
import { AuthContext } from "./AuthContext";
import OpenPosistions from "./OpenPositions";
import Chart from "./Chart.js";
const currencies = {
  "USD": "$",
  "EUR": "€",
  "GBP": "£",
}
function App({ config }) {
  const navigate = useNavigate();
  const firebase = loadFirebase(config);
  const  auth = config.auth;
  const { currentUser } = useContext(AuthContext);
  const [configs, setCofigs] = useState([]);
  const [cashBalance, setCashBalance] = useState(0);
  const [chartOneBars, setChartOneBars] = useState([]);
  const [chartTwoBars, setChartTwoBars] = useState([]);
  const [openPositions, setOpenPositions] = useState({});
  const [openPositionsValue, setOpenPositionsValue] = useState({});
  const [logs, setLogs] = useState([]);
  const [lastLogs, setLastLogs] = useState({});
  const [orders, setOrders] = useState([]);
  const [currentTime, setCurentTime] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tolalPositionsValue, setTotalPositionsValue] = useState(0);
  // const [enabled, setEnabled] = useState(false);

  const [isRunning, setIsRunning] = useState(false)

  const [onlySignals, setOnlySignals] = useState(false);

  const [sendText, setSendText] = useState(false);
  const [sendCall, setSendCall] = useState(false);

  const [documentTitle, setDocumentTitle] = useState("Trading Dashboard");

  const [style, setStyle] = useState({ backgroundColor: 'white' });

  const [showNewConfigModal, setShowNewConfigModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const[configToEdit, setConfigToEdit] = useState(null);
  const [accountsInfo, setAccountsInfo] = useState({});
  const [positionsInfo, setPositionsInfo] = useState({});
  const [currentAccount, setCurrentAccount] = useState(null); 
  const [currentAccountPositions, setCurrentAccountPositions] = useState(null); 

  // const [lastPrice, setLastPrice] = useState(0);
  // const [vol5minby30min, setVol5minby30min] = useState(0);
  // const [vol5minby1hour, setVol5minby1hour] = useState(0);
  // const [vol5min, setVol5min] = useState(0);
  // const [vol30min, setVol30min] = useState(0);
  // const [vol1hour, setVol1hour] = useState(0);

  // "lastPrice":lastPrice , 
  //           "vol5minby30min":vol5minby30min, 
  //           "vol5minby1hour": vol5minby1hour,
  //           "vol5min": vol5min, 
  //           "vol30min": vol30min, 
  //           "vol1hour": vol1hour

  useEffect(() => {
    
    if(logs.length > 0){
      // console.log("logs", logs[0].msg)
      setLastLogs({ ...lastLogs, [logs[0].bot]: logs[0]})
    }
     
  }, [logs]);

  useEffect(() => {
    // console.log("update 123");
    console.log({positionsInfo, currentAccount})
    if (positionsInfo && currentAccount){
      setCurrentAccountPositions(positionsInfo[currentAccount]);
      // console.log("positionsInfo", positionsInfo[currentAccount])
      let sum = 0;
      positionsInfo[currentAccount]?.forEach((position) => {
        sum += parseInt(position?.contract?.multiplier || 1) * position.position * position.price;
      });
      setTotalPositionsValue(sum);
    }
  }, [currentAccount, positionsInfo]);
  useEffect(() => {

    // console.log("update all")
    // firebase.configsRef.get().then((configsSnapshot) => {

    //   const tmpConfigs = [];
    //   configsSnapshot.forEach((configSnapshot) => {
    //     const config = configSnapshot.data();
    //     // config.id = configSnapshot.id;
    //     // setCofigs(prev => [...prev, config]);
    //     tmpConfigs.push(config);
    //   });
    //   setCofigs([...tmpConfigs]);
    // });

    
    firebase.positionsRef.onSnapshot((snapshot) => {
      // console.log(snapshot.data());
      setPositionsInfo(snapshot.data());
      
    });
    firebase.accountInfoRef.onSnapshot((snapshot) => {
      // console.log(snapshot.data());
      const accounts = snapshot.data();
      setAccountsInfo(accounts);
      if(Object.keys(accounts).length > 0){
        setCurrentAccount(Object.keys(accounts)[0]);
        // setCurrentAccountPositions(positionsInfo[Object.keys(accounts)[0]]);
      };
      //
    });
    firebase.configsRef.onSnapshot((snapshots) => {
      const tmpConfigs = [];
      snapshots.forEach((configSnapshot) => {
        const config = configSnapshot.data();
        tmpConfigs.push(config);
      });

      
      setCofigs([...tmpConfigs]);
    });

    firebase.openPositionsRef.onSnapshot((snapshots) => {
      // console.log("openPositions", `${config.name}-${config.symbol}`)    
      const result = [];
      let value = 0;
      if(snapshots.empty) {
        setOpenPositions({});
        return;
      }
      snapshots.forEach((snapshot) => {
        const position = snapshot.data();
        position.id = snapshot.id;
        result.push(position);
        value += position.value;
      });
      const positions ={};
      const values = {};
      let configsKeys = result.map((position) => position.configName);
      if(configsKeys.length > 0){
        configsKeys = [...new Set(configsKeys)];
      }
      for(let configName of configsKeys){
        positions[configName] = result.filter((position) => position.configName === configName);
        values[configName] = positions[configName].reduce((a, b) => a + b.value, 0);
      }

      // if(result.length < 0) return;
      // console.log("openPositions", result)
      // console.log("openPositions", result)
      // console.log("open positions", `${config.name}-${config.symbol}`, result.length, Object.keys(openPositions).length);
      // if(result && result.length > 0){
      //   let tmp = {...openPositions};
      //   tmp[`${config.name}-${config.symbol}`] = result;
      //   console.log("open positions", tmp)
      //   setOpenPositions({...tmp});
      //   setOpenPositionsValue({ ...openPositionsValue, [`${config.name}-${config.symbol}`]: value});
      // }
     
      setOpenPositions({...positions});
      setOpenPositionsValue({ ...values});
      // setOpenPositionsValue({ ...openPositionsValue});
      
      // update background-color
      if (result.length > 0) {
        let pnlPercentage = result[0].pnlPercentage;
        let s1= {};
        // document.title =;
        setDocumentTitle(  `$${result[0].pnl?.toFixed(2)}    ${pnlPercentage?.toFixed(2)}%`)


        s1.backgroundColor = "white";
        if (pnlPercentage < 0 && pnlPercentage > -30) {
          s1.backgroundColor = "#ff000042";

        } 
        else if (pnlPercentage < -30) {
          s1.backgroundColor = "#ff0000a1";
        } else if(pnlPercentage > 0 && pnlPercentage < 30) {
          s1.backgroundColor = "#0bac6140";
        } else if(pnlPercentage > 30 ) {
          s1.backgroundColor = "#0bac61bd";
        }
        setStyle(s1);
      }else{
        setDocumentTitle("No open positions")
      }
    });
    firebase.cashBalanceRef.onSnapshot((snapshot) => {
      const result = snapshot.data();
      console.log("result", result)
      setCashBalance(result?.cashBalance || 0);
    });

    firebase.mainConfigRef.onSnapshot((snapshot) => { 
      const data = snapshot.data();
      // console.log("result", result)
      setCurentTime(data.currentTime?data.currentTime.toDate(): "no time from server");
    });
    firebase.controlPanelRef.onSnapshot((snapshot) => {
      const control = snapshot.data();

      setIsRunning(control.status === 'online');
      
      setStartDate(control.config.startDate?control.config.startDate.toDate(): "no time from server");
      setEndDate(control.config.endDate?control.config.endDate.toDate(): "no time from server");
      setSendText(control.sendText?control.sendText: false);
      setSendCall(control.sendCall?control.sendCall: false);  
      setOnlySignals(control.onlySignals?control.onlySignals: false);
    });

    firebase.logsRef.orderBy('timestamp', 'desc').limit(1000).onSnapshot((snapshots) => {
      const result = [];

      snapshots.forEach((snapshot) => {
        const log = snapshot.data();
        result.push({
          id: snapshot.id,
          ...log,
        });
      });

      setLogs(prev =>
        [...prev, ...result]
          .filter((val, idx, arr) => { 
            if (idx !== arr.findIndex(x => x.id === val.id)) {
              return false;
            }

            if (idx > 0 && val.msg === arr[idx - 1].msg) {
              arr[idx - 1]['occurrence'] = (arr[idx - 1]['occurrence'] || 0) + 1;
              return false;
            }

            return true;
          })
          .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)
          .slice(0, 3000)
      );
    });

    const timeToLocal = (originalTime) => {
      const d = new Date(originalTime * 1000);
      return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()) / 1000;
    }

    firebase.ordersRef.orderBy('timestamp', 'desc').limit(100).onSnapshot((snapshots) => {
      const result = [];

      snapshots.forEach((snapshot) => {
        const order = snapshot.data();

        if (snapshot.id !== 'lastOpenOrder') {
          order.open.time = timeToLocal(order.open.timestamp.seconds);
          order.close.time = timeToLocal(order.close.timestamp.seconds);

          result.push({
            id: snapshot.id,
            ...order,
          });
        }
      });

      setOrders(prev =>
        [...prev, ...result]
          .filter((val, idx, arr) => idx === arr.findIndex(x => x.id === val.id))
          .sort((a, b) => b.open.timestamp.seconds - a.open.timestamp.seconds)
      );
    });

    firebase.chartOneRef.onSnapshot((snapshot) => {
      const result = snapshot.data();

      const bars = result.bars
        .map(x => ({
          ...x,
          time: timeToLocal(x.time.seconds),
        }))
        .sort((a, b) => a.time - b.time);
      // console.log(bars[0])
      setChartOneBars(bars);
    });

    firebase.chartTwoRef.onSnapshot((snapshot) => {
      const result = snapshot.data();

      const bars = result.bars
        .map(x => ({
          ...x,
          time: timeToLocal(x.time.seconds),
        }))
        .sort((a, b) => a.time - b.time);
      // console.log(bars[0])
      setChartTwoBars(bars);
    });
  }, []);
  const sum = Object.keys(openPositionsValue).reduce((a, b) => a + openPositionsValue[b], 0);
  const accountValue = useMemo(() => cashBalance + tolalPositionsValue, [cashBalance, tolalPositionsValue]);

  const startBot = () => {
    firebase.controlPanelRef.set({ desiredStatus: 'online' }, { merge: true })
  }

  
  const stopBot = () => {
    firebase.controlPanelRef.set({ desiredStatus: 'stopped' }, { merge: true })
  }
  
  const exitPosition = (config, id) => {
    if (window.confirm("Are you sure you want to force exit this position?")) {
      //(config.name, config.symbol)
      firebase.openPositionsRef.doc(id).set({ forceExit: true }, { merge: true })
    }
  }
  const logout = () => {
    auth.signOut().then(() => {
      navigate("/")
    });
  }
  // let style = {
  //   backgroundColor: "white"
  // };
  if(!currentUser) {
    navigate("/")
  }
  let lastSignedInDate = localStorage.getItem('lastSignedInDate');
  if(new Date().getDate() - parseFloat(lastSignedInDate) > 3 * 24 * 60 * 60 * 1000){
    firebase.auth().signOut();
  }

  const startBotHanler = async (config) => {
    await firebase.configsRef.doc(config.name).set({ status: 'online' }, { merge: true })
  }
  const stopBotHandler = async (config) => {
    await firebase.configsRef.doc(config.name).set({ status: 'stopped' }, { merge: true })
  }
  const exitBotHanler = (config) => {
    //exit all open positions
    //stop Bot
  }
  const editHandler = (config) => {
    setConfigToEdit(config);
    setEditing(true);
    setShowNewConfigModal(true);
  }

  return <>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <Container className="bot-container">
        <Row>
            <Col md={10} />
            <Col md={2} style={{textAlign:"right", paddingTop:"5px"}}><Button onClick={logout}>Log out</Button></Col>
        </Row>

        <Row>
          <Col>
          <ListGroup className="my-4 top">
              {!!config.paperTrading ? (
                <Badge bg="warning">Bot {config.version} - Paper Trading</Badge>
              ) : (
                <Badge bg="success">Bot {config.version} - Real Money</Badge>
              )}
              <ListGroup.Item>
                <h6>Account Value: ${(accountValue || 0).toLocaleString("en-US")}</h6>
              </ListGroup.Item>
              <ListGroup.Item>
                
              <h6>Cash Balance: ${(cashBalance || 0).toLocaleString("en-US")}</h6>
              </ListGroup.Item>
              <ListGroup.Item>
              <h6>Time: {currentTime?.toLocaleTimeString()}</h6>
              </ListGroup.Item>

              </ListGroup>
          </Col>
          <Col md={6}>
          <ListGroup className="my-4 top">
          <Badge bg="success">Bot {config.version} - Open Posistions</Badge>
                {currentAccountPositions && currentAccountPositions.map((position, key) => (
                  <ListGroup.Item key={key}>
                    <Row>
                      {/* {JSON.stringify(position)} */}
                      <Col md={2}>
                        <strong>{position.contract.symbol}</strong>
                      </Col>
                      <Col md={2}>
                        <strong>{position.position}</strong>
                      </Col>
                      {/* <Col md={2}>
                        <strong>{currencies[position?.contract?.currency]}{position?.avgCost?.toLocaleString("en-US")}</strong>
                      </Col> */}
                      <Col md={2}>
                        <strong>{currencies[position?.contract?.currency]}{(position.position * position?.avgCost).toLocaleString("en-US")}</strong>
                      </Col>
                      <Col md={2}>
                        {/* {JSON.stringify(position)} */}
                        <strong>{currencies[position?.contract?.currency]}{( parseInt(position?.contract?.multiplier || 1) * position.position * position?.price).toLocaleString("en-US")}</strong>
                      </Col>
                      {/* <Col md={2}>
                        <strong>-{position.pnl}-</strong>
                      </Col> */}
                      <Col md={2}> 
                        <strong>{position?.contract?.secType}</strong>
                      </Col>
                      <Col>
                      <BFORM.Check 
                        type="checkbox" 
                        label="auto"
                        />
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
                {!currentAccountPositions && <ListGroup.Item>
                  <Row>
                    <Col md={12}>
                      <strong>No open positions</strong>
                    </Col>
                  </Row>
                </ListGroup.Item>}
          </ListGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <ListGroup className="my-4 top">
              <Badge bg="success">Chart 1</Badge>
              
              <ListGroup.Item>
                <Chart chartId={1} firebase={firebase} data={chartOneBars} orders={orders} />
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={6}>
            <ListGroup className="my-4 top">
              <Badge bg="success">Chart 1</Badge>
              
              <ListGroup.Item>
                <Chart chartId={2} firebase={firebase} data={chartTwoBars} orders={orders} />
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>

        <ListGroup className="my-4 top">
          <Badge bg="success">Bot Configs</Badge>
          <ListGroup.Item>
            <div className="bot-configs">
              {configs && configs.map((config, key) => (
                <>
                <Row key={key} style={{margin: "2px", fontSize: "12px", textAlign:"left", borderBottom: "solid 1px #efefef"}}>
                  <Col md={2} className="config-name" style={{position: "relative"}}>
                    {config.synthetic && <Badge bg="secondary">Synth</Badge>}
                    {config.name}-<strong>{config.symbol}</strong>
                    <br />  
                    {config.synthetic && <span>USD{config.syntheticTradingStartingBalance}</span>}
                  </Col>
                  <Col md={5} className="positions">
                    {(openPositions && openPositions[`${config.name}-${config.symbol}`] && openPositions[`${config.name}-${config.symbol}`].length > 0) &&
                      <OpenPosistions expiration={config?.config?.expiry?.fop} style={style} openPositions={openPositions[`${config.name}-${config.symbol}`]} exitPosition={(id)=>exitPosition(config, id)} />
                      
                    }
                    {!(openPositions && openPositions[`${config.name}-${config.symbol}`] && openPositions[`${config.name}-${config.symbol}`].length > 0) &&
                      <span className="open-positions gray"> No open positions</span>
                    }
                  </Col>

                  <Col md={5}>
                    <Row >
                      <Col style={{textAlign:"left"}}>
                        {config.status !== 'online' && config.config.startDate.toDate().toLocaleString()}
                        {config.status === 'online' && config.config.endDate.toDate().toLocaleString()}
                      </Col>
                      <Col style={{textAlign:"right"}}>
                        <BFORM.Check type="checkbox" label="" checked={config.enabled} onChange={async (e) => {await firebase.configsRef.doc(config.name).set({ enabled: e.target.checked }, { merge: true }); }} style={{textAlign: "center", display: "inline-block"}} />

                        {!config.status || config.status === 'stopped' && 
                          <Button variant="success" onClick={()=> startBotHanler(config)} style={{padding: "2px", marginRight: "5px"}} {...(config.enabled)? {}: {disabled: true}}>
                            Start
                          </Button>
                        }

                        {config.status && config.status === 'online' &&
                          <Button variant="danger" onClick={()=> stopBotHandler(config)} style={{padding: "2px", marginRight: "5px"}}>
                            Stop
                          </Button>
                        }

                        <Button variant="secondary" onClick={()=> editHandler(config)} style={{padding: "2px", marginRight: "5px"}}>
                          Edit
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {config?.data?.lastPrice && <span>${config.data.lastPrice.toLocaleString({ style: 'currency', currency: 'USD', minimumFractionDigits: 3 })}</span>}
                      </Col>
                      <Col md={4}>
                        {config?.data?.vol5min && <span>σ: {(config.data.vol5min).toFixed(7)}</span>}
                      </Col>
                      {/* <Col>
                        {config?.data?.vol5minby30min && <span>{(config.data.vol5minby30min * 100).toFixed(2)}%</span>}
                      </Col> */}
                      {/* <Col style={{textAlign:"right"}}>
                        {config?.data?.vol5minby1hour && <span>{(config.data.vol5minby1hour * 100).toFixed(2)}%</span>}
                      </Col> */}
                    </Row>
                  </Col>
                </Row>

                <Row style={{margin: "2px", fontSize: "12px", textAlign:"left", borderBottom: "solid 1px #efefef"}}>
                  {lastLogs && lastLogs[config.name] && 
                    <p bg="black" style={{maxWidth: 700}}> [{lastLogs[config.name].timestamp?.toDate().toLocaleString()}] -  {lastLogs[config.name]?.msg}</p>
                  }
                </Row>
                </>
              ))}
              {(!configs || configs?.length <=0 ) && (<>there is no config defined</>)}
            </div>
            <div className="config-operations">
              <Row>
                <Col md={10}></Col>
                <Col md={2} style={{textAlign: "left"}}>
                  <Button onClick={()=>{
                    setEditing(false);
                    setConfigToEdit(null);
                    setShowNewConfigModal(!!!showNewConfigModal)
                  }}>New Trade</Button>
                </Col>
                <NewConfigModal editing={editing} configToEdit={configToEdit} show={showNewConfigModal} setShowNewConfigModal={setShowNewConfigModal} config={config}/>
              </Row>
            </div>
          </ListGroup.Item>
        </ListGroup>
        
        <ListGroup className="my-4" style={{ maxWidth: '95vw', maxHeight: 600, overflow: 'scroll' }}>
          <Badge bg="success">Live Logs</Badge>

          {logs.map((log, key) => (
            <ListGroup.Item key={key} style={{ whiteSpace: 'pre-line' }}>
              <Badge bg="black"> [{log.timestamp.toDate().toLocaleString()}] - [{log.bot}]</Badge>
              {' ' + log.msg + ' '}
              {log.occurrence && <Badge bg="secondary" className="pull-right">{log.occurrence}</Badge>}
              </ListGroup.Item>
          ))}
        </ListGroup>

        <ListGroup className="my-4">
          <Badge bg="success">Orders</Badge>
            {orders.map((order, key) => (
              <ListGroup.Item key={key}>
                {
                  order.exit ?
                    <>
                      <Badge bg="black">Exit</Badge> {order.exit?.timestamp?.toDate().toLocaleString() || "N/A"}<br/>
                    </>
                  :
                    <>
                      <Badge bg="black">Open</Badge> {order.open?.timestamp?.toDate().toLocaleString() || "N/A"}<br/>
                      <Badge bg="black">Close</Badge> {order.close?.timestamp?.toDate().toLocaleString() || "N/A"}<br/>
                      <Badge bg="black">PNL</Badge> {order.pnlPercentage || "N/A"}%
                    </>
                }
                
              </ListGroup.Item>
            ))}
        </ListGroup>

          {/* <ListGroup>
            <ListGroup.Item>
              Nasdaq 100: $0,000.00 [0%]
            </ListGroup.Item>

            <ListGroup.Item>
              Crude Oil: $0,000.00 [0%]
            </ListGroup.Item>

            <ListGroup.Item>
              TSLA: $0,000.00 [0%]
            </ListGroup.Item>

            <ListGroup.Item>
              NVIDA: $0,000.00 [0%]
            </ListGroup.Item>

            <ListGroup.Item>
              AMZN: $0,000.00 [0%]
            </ListGroup.Item>

            <ListGroup.Item>
              AAPL: $0,000.00 [0%]
            </ListGroup.Item>
          </ListGroup> */}
      </Container >
  </>
}

export default App;


//if pnl < 0 then light red 
//if pnl < -30 med red 
//if pnl < -50 dark red
//if pnl > 0 then light green
//if pnl > 30 med green
//if pnl > 50 dark green

//if there is a check mark next to position in upper right, and there is a process live for that symbol (NQ),
//then bot should automatically sell that position if it is not found in the live positions database
//the reason is because the bot may have bought that position but it didn't get into live position database

//additionally, when buy a new position, bot should first set the target inside database, then buy the position
//once trade executes, bot should reconcile the position with the target, and if they don't match, then bot 
//should sell the position and buy again with the correct target including quantity 

//this also will help with multiple NQ bots running at the same


// we have two runing bots. both for NQ
//each NQ bot always has a contract ID, strike and right, tagged to the process