import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { Row, Col, Form, Button } from 'react-bootstrap';

function Chart({ chartId, firebase, data, orders }) {
  const chartContainerRef = useRef();
  const candleSeriesRef = useRef();
  const shortLineSeriesRef = useRef();
  const longLineSeriesRef = useRef();
  const volatility5MinSeriesRef = useRef();
  const volatility30MinSeriesRef = useRef();

  const [enabled, setEnabled] = useState(false);
  const [barSize, setBarSize] = useState();
  const [duration, setDuration] = useState();

  const [showControls, setShowControls] = useState(true);
  const [volEnabled, setVolEnabled] = useState(true);
  const [emaEnabled, setEmaEnabled] = useState(true);
  const [bearsEnabled, setBearsEnabled] = useState(true);
  const [ordersEnabled, setOrdersEnabled] = useState(true);

  const enabledProp = chartId === 1 ? 'chartOneEnabled' : 'chartTwoEnabled';
  const barSizeProp = chartId === 1 ? 'chartOneBarSize' : 'chartTwoBarSize';
  const durationProp = chartId === 1 ? 'chartOneDuration' : 'chartTwoDuration';

  const calculateEMA = (data, span) => {
    const multiplier = 2 / (span + 1);
    const emaValues = [];
    let sum = 0;
  
    for (let i = 0; i < data.length; i++) {
      if (i < span) {
        sum += data[i].close;
        emaValues.push({ time: data[i].time, value: Number(null) });
      } else {
        if (i === span) {
          emaValues.push({ time: data[i].time, value: sum / span });
        } else {
          const ema = (data[i].close - emaValues[i - 1].value) * multiplier + emaValues[i - 1].value;
          emaValues.push({ time: data[i].time, value: ema });
        }
      }
    }
  
    return emaValues;
  }

  const calculateVolatility = (data) => {
    let Ris = [];
    for (let i = 1; i < data.length; i++) {
        let Ri = (data[i] - data[i-1]) / data[i-1];
        Ris.push(Ri);
    }
    let R = Ris.reduce((a, b) => a + b, 0) / Ris.length;
    let total = 0;
    for (let i = 0; i < Ris.length; i++) {
        let Ri = Ris[i];
        let xxx = (Ri - R) ** 2;
        total += xxx;
    }
    let variance = total / (Ris.length - 1);
    return Math.sqrt(variance);
  }

  const calculateVolatilitySeries = (data, span = 11) => {
    // vol5min = calculate_volatility(data5.tail(11)["average"].values)
    // vol30min = calculate_volatility(data5.tail(61)["average"].values)
    // vol1hour = calculate_volatility(data5.tail(121)["average"].values)
    // vol2hour = calculate_volatility(data5.tail(241)["average"].values)

    const result = [];

    for (let i = 0; i < data.length; i++) {
      if (i - span < 0) {
        // console.log(i, span);
        // result.push({ time: data[i].time, value: Number(null) });
      } else {
        const volatility = calculateVolatility(data.slice(i - span, i).map(x => x.average));
        // console.log(volatility)
        result.push({ time: data[i].time, value: volatility });
      }
    }

    return result;
  }

  useEffect(() => {
    firebase.chartConfigRef.get().then(snapshot => {
      const data = snapshot.data();
      setBarSize(data[barSizeProp] || '');
      setDuration(data[durationProp] || '');
    });
  }, []);

  useEffect(() => {
    console.log(chartContainerRef.current)
    if (chartContainerRef.current) {
      // console.log(chartContainerRef.current)
      // console.log('create chart');
      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.offsetWidth,
        height: 300,
        autoSize: true,
        timeScale: {
          timeVisible: true,
        },
        leftPriceScale: {
          visible: chartContainerRef.current.offsetWidth > 300,
        },
        rightPriceScale: {
          visible: true
        },
        layout: {
          fontSize: chartContainerRef.current.offsetWidth > 500 ? 12 : 8
        },
        crosshair: {
          mode: 0
        },
      });
      candleSeriesRef.current = chart.addCandlestickSeries();

      const shortLineSeries = chart.addLineSeries({ color: 'blue' });
      const longLineSeries = chart.addLineSeries({ color: 'red' });
      const volatility5MinSeries = chart.addLineSeries({ color: 'black', priceScaleId: 'left', priceFormat: { type: 'price', minMove: 0.00001 } });
      const volatility30MinSeries = chart.addLineSeries({ color: 'gray', priceScaleId: 'left', priceFormat: { type: 'price', minMove: 0.00001 } });

      shortLineSeriesRef.current = shortLineSeries;
      longLineSeriesRef.current = longLineSeries;
      volatility5MinSeriesRef.current = volatility5MinSeries;
      volatility30MinSeriesRef.current = volatility30MinSeries;

      // candleSeries.setData(data);

      return () => chart.remove();
    }
  }, [chartContainerRef?.current, enabled]);

  useEffect(() => {
    if (showControls) {
      setTimeout(() => setShowControls(false), 10 * 1000);
    }
  }, [showControls]);

  useEffect(() => {  
    if (candleSeriesRef?.current && data) {
      const markers = [];
      if (shortLineSeriesRef?.current && longLineSeriesRef?.current && data) {
        let emaShort = [];
        let emaLong = [];

        if (emaEnabled || bearsEnabled) {
          emaShort  = calculateEMA(data, 12);
          emaLong = calculateEMA(data, 26);
        }

        if (emaEnabled) {
          shortLineSeriesRef?.current?.setData(emaShort);
          longLineSeriesRef?.current?.setData(emaLong);
        } else {
          shortLineSeriesRef?.current?.setData([]);
          longLineSeriesRef?.current?.setData([]);
        }

        if (volEnabled) {
          const volatility5Min = calculateVolatilitySeries(data);
          const volatility30Min = calculateVolatilitySeries(data, 61);

          volatility5MinSeriesRef?.current?.setData(volatility5Min);
          volatility30MinSeriesRef?.current?.setData(volatility30Min);
        } else {
          volatility5MinSeriesRef?.current?.setData([]);
          volatility30MinSeriesRef?.current?.setData([]);
        }
        
        if (emaShort.length !== emaLong.length) {
          console.error("EMA arrays do not match in length.");
        }
      
        if (bearsEnabled) {
          for (let i = 1; i < emaShort.length; i++) {
            if (
              (emaShort[i - 1].value < emaLong[i - 1].value && emaShort[i].value > emaLong[i].value) ||
              (emaShort[i - 1].value > emaLong[i - 1].value && emaShort[i].value < emaLong[i].value)
            ) {
              const direction = emaShort[i].value > emaLong[i].value ? "BULL" : "BEAR"

              markers.push({
                time: emaShort[i].time,
                position: 'aboveBar',
                color: direction === 'BULL' ? 'green' : 'red',
                text: direction,
                shape: 'arrowDown'
              });
            }
          }
        }

        if (ordersEnabled) {
          orders.forEach(order => {
            markers.push({
              time: order.open.time,
              position: 'aboveBar',
              color: 'black',
              text: 'Open',
              shape: 'arrowDown'
            });

            markers.push({
              time: order.close.time,
              position: 'aboveBar',
              color: 'purple',
              text: 'Close',
              shape: 'arrowDown'
            });
          });
        }
      }

      candleSeriesRef.current.setData(data);
      candleSeriesRef.current.setMarkers(markers.sort((a, b) => a.time - b.time));
    }
  }, [
    candleSeriesRef?.current,
    shortLineSeriesRef?.current,
    longLineSeriesRef?.current,
    volatility5MinSeriesRef?.current,
    volatility30MinSeriesRef?.current,
    data,
    orders,
    enabled,
    volEnabled,
    emaEnabled,
    bearsEnabled,
    ordersEnabled
  ]);

  const onToggleChart = (e) => {
    setEnabled(!enabled);
    firebase.chartConfigRef.set({ [enabledProp]: !enabled }, { merge: true });
  }

  const onChangeBarSize = (e) => {
    const value = e.target.value || '';
    setBarSize(value);
    firebase.chartConfigRef.set({ [barSizeProp]: value }, { merge: true });
  }

  const onChangeDuration = (e) => {
    const value = e.target.value || '';
    setDuration(value);
    firebase.chartConfigRef.set({ [durationProp]: value }, { merge: true });
  }

  return (
    <>
      <Row className="mt-1">
        { enabled && (
          <>
            <Col md={4}>
              <span>Chart Bar Size:</span>
              <Form.Select value={barSize} onChange={onChangeBarSize}>
                <option disabled>Chart Bar Size</option>
                <option value="10 secs">10 secs</option>
                <option value="15 secs">15 secs</option>
                <option value="30 secs">30 secs</option>
                <option value="1 min">1 min</option>
                <option value="3 mins">3 mins</option>
                <option value="5 mins">5 mins</option>
                <option value="10 mins">10 mins</option>
                <option value="15 mins">15 mins</option>
                <option value="30 mins">30 mins</option>
                <option value="1 hour">1 hour</option>
                <option value="4 hours">4 hour</option>
                <option value="8 hours">8 hour</option>
              </Form.Select>
            </Col>

            <Col md={4}>
              <span>Chart Duration:</span>
              <Form.Select value={duration} onChange={onChangeDuration}>
                <option disabled>Chart Duration</option>
                <option value="1 D">1 D</option>
                <option value="2 D">2 D</option>
                <option value="3 D">3 D</option>
                <option value="5 D">5 D</option>
                <option value="17 D">17 D</option>
                <option value="34 D">34 D</option>
                <option value="50 D">50 D</option>
                <option value="100 D">100 D</option>
                <option value="365 D">365 D</option>

              </Form.Select>
            </Col>
          </>
        )}

        <Col md={4} className="mt-3">
          <Button size="lg" onClick={onToggleChart}>{enabled ? 'Disable Chart' : 'Enable Chart'} {chartId}</Button>
        </Col>
      </Row>

      {enabled && <>
        <div ref={chartContainerRef} />

        <Form style={{textAlign: 'left'}}>
          {
            showControls ?
              <>
                <Form.Check
                  type="switch"
                  label="Display Volatility?"
                  checked={volEnabled}
                  onChange={(e) => setVolEnabled(e.target.checked)}
                />

                <Form.Check
                  type="switch"
                  label="Display EMA Crossovers?"
                  checked={emaEnabled}
                  onChange={(e) => setEmaEnabled(e.target.checked)}
                />

                <Form.Check
                  type="switch"
                  label="Display Bears/Bulls?"
                  checked={bearsEnabled}
                  onChange={(e) => setBearsEnabled(e.target.checked)}
                />

                <Form.Check
                  type="switch"
                  label="Display Orders?"
                  checked={ordersEnabled}
                  onChange={(e) => setOrdersEnabled(e.target.checked)}
                />
              </>
            :
              <Form.Check
                type="switch"
                label="Show Settings?"
                checked={showControls}
                onChange={(e) => setShowControls(e.target.checked)}
              />
          }
        </Form>
      </>}
    </>
  );
}

export default Chart;
