import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDz-7t5_8hDCXHMb_abUK9qcvaSI6Hy5lw",
  authDomain: "ibkr-api.firebaseapp.com",
  projectId: "ibkr-api",
  storageBucket: "ibkr-api.appspot.com",
  messagingSenderId: "403040274109",
  appId: "1:403040274109:web:98362a07a0971a9768d9d4",
};

firebase.initializeApp(firebaseConfig);

export default firebase;