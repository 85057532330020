import React, { useState  } from 'react';
import {useNavigate} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { Col, Row, Container } from 'react-bootstrap';



const Login = ({firebase}) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    // const [isloading, setIsLoading] = useState(true);
    
    firebase.auth().onAuthStateChanged((user) => {
        //console.log(user)

        if (user) {
          
          // const idToken = firebase.auth().currentUser?.getIdToken();
          // console.log(idToken);
          let lastSignedInDate = localStorage.getItem('lastSignedInDate');
          
          if(lastSignedInDate){
            if(new Date().getDate() - parseFloat(lastSignedInDate) <= 3 * 24 * 60 * 60 * 1000){
              navigate('/bot');
            }else{
              firebase.auth().signOut();
            }
          }

         
          // navigate('/bot');
        } else {
          // No user is signed in.
          // setIsLoading(false);
        }
    });
  const signInWithEmailAndPassword = async (event) => {
    event.preventDefault();
    setError(null);

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      localStorage.setItem('lastSignedInDate', new Date().getTime());
      navigate('/bot');
    } catch (error) {
        alert(error.message)
      setError(error.message);
    }
  };
  // if(isloading) return (<div>Loading...</div>)

  return (
    <div style={{padding:"20px", backgroundColor:"white", borderRadius:"10px"}}>
      <h1>Login</h1>
      <Form onSubmit={signInWithEmailAndPassword}>
        <Form.Group>
            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)}>

            </Form.Control>
        </Form.Group>
        <br />
    
        <Form.Group>
            <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}>
            </Form.Control>
        </Form.Group>
        <br />
        <Form.Group>
            <br />  
            <Button type="submit">Login</Button>
        </Form.Group>
        
      </Form>
      {error && <p>{error}</p>}
    </div>
  );
};

export default Login;