import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import App from "./App";
import reportWebVitals from "./reportWebVitals";

// import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./Login";

import firebase from './firebaseConfig';
import { AuthProvider } from './AuthContext';

// const app = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = firebase.firestore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div style={{display: 'flex', gap: 30, padding: '0 30px'}}>
      {/* <App config={{
          db,
          symbol: "nq",
          version: "v1",
          paperTrading: true,
      }} />
      <App config={{
          db,
          symbol: "nq",
          version: "v2",
          paperTrading: true,
      }} /> */}
      {/* <App config={{
          db,
          symbol: "nq",
          version: "v3",
      }} /> */}

      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login firebase={firebase}/>}/>
            <Route path="/bot"  element={ <App config={{db,symbol: "nq",version: "v3", auth: auth}} />}/>
            <Route path="*" component={() => <div>404 Not Found</div>} />
          </Routes>
        </Router>
    </AuthProvider>
     

    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
